import { createStore, compose, applyMiddleware } from 'redux';

import createSagaMiddleware from 'redux-saga';

import { reduxSearch } from 'redux-search'

import { config as searchConfig } from './search';

import rootReducer from './reducers';

import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

if (process.env.NODE_ENV !== 'production') {

  Map.prototype.toJSON = function() {
    var obj = {};
    this.forEach((value, key) => obj[key] = value);
    return obj;
  }
}


//const composeEnhancers = process.env.NODE_ENV === "production" ? compose : composeWithDevTools;
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const enhancers = composeEnhancers(

  applyMiddleware( sagaMiddleware ),
  reduxSearch( searchConfig ),
);

export function configureStore( preloadedState) {

  const store = createStore(
    rootReducer(),
    preloadedState,
    enhancers
  );

  sagaMiddleware.run(rootSaga);

  return store;
};
