import React, { useState } from 'react';

import { Box, Button, Form, Header, Heading, Layer, TextInput, ThemeContext } from 'grommet';
import { Close, FormClose, Search } from 'grommet-icons';

import { useDispatch, useSelector } from 'react-redux';

import { searchItems, filterItems } from 'actions/menu';
import { selectTags } from 'selectors/menu';

const tagTheme = {

  button: {

    primary: {

      background: {

        color: 'neutral-1',
      },

      color: 'neutral-2',
    },
  },
};

const SearchLayer = ({search = '', filterTags = [], onClose}) => {

  const dispatch = useDispatch();

  const [values, setValues] = useState( { search } );

  const [selectedTags, setSelectedTags] = useState( filterTags );

  const tags = useSelector( selectTags );

  const handleOnSubmit = () => {

    dispatch( searchItems( values.search ) );
    dispatch( filterItems( selectedTags ) );
    onClose();
  };

  return (
    <Layer
      full="horizontal"
      position="top"
      responsive={false}
    >
      <Header pad={{left: 'medium'}}>
        <Heading level={2} margin="small">Search</Heading>
        <Button icon={<Close/>} onClick={onClose}/>
      </Header>
      <Box flex overflow="auto">
      <Form
        value={values}
        onChange={(value) => setValues( value )}
        onSubmit={handleOnSubmit}
      >
        <Box pad="medium" gap="medium">
          <Box border="all" direction="row" style={{borderRadius: 10}}>
            <TextInput
              name="search"
              plain
              placeholder="Search..."
              icon={<Search/>}
            />
            {
              values.search.length > 0 &&
              <Button
                plain
                margin={{right: 'small'}}
                icon={<FormClose/>}
                onClick={() => setValues( {...values, search: '' } )}
              />
            }
          </Box>
          <Box direction="row" wrap>
            <ThemeContext.Extend value={tagTheme}>
            {
              tags.map( (tag, index) => {

                const isSelected = selectedTags.includes( tag );

                return (
                  <Button
                    key={`${tag}_${index}`}
                    margin="xsmall"
                    size="small"
                    primary={isSelected} seconary={!isSelected}
                    label={tag}
                    onClick={() => isSelected ? setSelectedTags( selectedTags.filter( (t) => t !== tag )) : setSelectedTags( [ ...selectedTags, tag ])}
                  />
                );
              })
            }
            </ThemeContext.Extend>
          </Box>
          <Box align="end" margin={{top: 'medium', bottom: 'medium'}}>
            <Button primary label="Apply" type="submit"/>
          </Box>
        </Box>
      </Form>
      </Box>
    </Layer>
  );
};

export default SearchLayer;
