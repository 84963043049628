import React, { useEffect } from 'react';

import { Box, Button, Heading, Text } from 'grommet';
import { Close, FormAdd, FormSubtract } from 'grommet-icons';

import { useDispatch, useSelector } from 'react-redux';

import { setOptionValue, updateQuantity } from 'actions/menu_item';
import { addItem, updateItem } from 'actions/order';

import { selectMenuItem } from 'selectors/menu_item';

import ItemOptionRadioTable from 'components/ItemOptionRadioTable';
import ItemOptionCheckBoxTable from 'components/ItemOptionCheckBoxTable';

const MenuItemPage = ({history, match, location: browserLocation, ...rest}) => {

  const dispatch = useDispatch();

  const { item : menuItem, optionValues, quantity, orderItemId } = useSelector( selectMenuItem );

  const renderItemOption = (itemOption) => {

    const { id, displayName, type, options } = itemOption;

    const optionsArray = [...options.values() ];

    return (
      <Box pad="medium" key={id}>
        <Box margin={{bottom: 'medium'}} border={[{ size: 'small', side: 'top', color: 'light-2' }]}/>
        <Box>
          <Heading level={3} margin="small">{displayName}</Heading>
          {
            (type === 'radio' || type === 'size') &&
            <ItemOptionRadioTable
              options={optionsArray}
              value={optionValues.get( id )}
              onChange={(value) => dispatch( setOptionValue( id, value ) )}/>
          }
          {
            type === 'checkbox' &&
            <ItemOptionCheckBoxTable
              options={optionsArray}
              value={optionValues.get( id )}
              onChange={(value,checked) => dispatch( setOptionValue( id, value, checked ? 'add' : 'remove' ) )}
            />
          }
        </Box>
      </Box>
    );
  }

  const handleOrder = () => {

    if( orderItemId ) {

      dispatch( updateItem( orderItemId, optionValues, quantity ) );
    }
    else {

      dispatch( addItem( menuItem, optionValues, quantity ) );
    }

    history.goBack();
  };

  useEffect( () => {

    window.scrollTo(0,0);
  }, [] );

  useEffect( () => {

    if( !menuItem ) {

      history.replace( '/');
      return;
    }

    // set default radio values
    if( menuItem.options ) {

      for( const option of menuItem.options ) {

        if( (option.type === 'radio' || option.type === 'size') && !optionValues.get( option.id ) ) {

          const { value } = [ ...option.options.values() ][ 0 ];

          dispatch( setOptionValue( option.id, value ) );
        }
      }
    }
  }, [dispatch, history, menuItem, optionValues]);

  if( !menuItem ) {

    return null;
  }

  return (
    <Box style={{paddingBottom: 150}}>
      <Box pad="medium" background="neutral-2" align="center" justify="between" direction="row" animation="fadeIn">
        <Heading level={2}>{menuItem.name}</Heading>
        <Button pad="xsmall" icon={<Close/>} onClick={() => history.goBack()}/>
      </Box>
      <Box pad="medium" gap="medium">
        {menuItem.desc && <Text size="large" color="dark-2">{menuItem.desc}</Text>}
        {menuItem.price && <Text size="medium" color="dark-2">${menuItem.price}</Text>}
      </Box>
      {
        menuItem.options &&
        menuItem.options.map( renderItemOption )
      }
      <div style={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10}}>
        <Box direction="row" background="rgba(200,200,200,0.9)" align="center" justify="between">
          <Box direction="row" align="center" margin="medium" justify="center">
            <Button secondary size="medium" icon={<FormSubtract/>} onClick={() => quantity > 1 && dispatch( updateQuantity( quantity - 1 ))}/>
            <Text margin="small" size="medium">{quantity}</Text>
            <Button secondary size="medium" icon={<FormAdd/>} onClick={() => quantity < 30 && dispatch( updateQuantity( quantity + 1 ))}/>
          </Box>
          <Box margin="medium">
            <Button primary size="medium" label={orderItemId ? "Update" : "Add"} onClick={handleOrder}/>
          </Box>
        </Box>
      </div>
    </Box>
  )
};


export default MenuItemPage;
