import { call, takeLatest, put } from 'redux-saga/effects';

import { getMenu } from 'lib/api';

import { MENU_LOAD, menuLoaded } from 'actions/menu';
import { loadOrder } from 'actions/order';

//import { menu as testMenu } from './test-menu';

// Menu Id
// qUhr40yun7aiTx96a5PK_
//
//

function* handleMenuLoad( action ) {

  const { payload: { id } } = action;

  try {

    const menu = yield call( getMenu, id );

    yield put( menuLoaded( id, menu ) );

    // load persisted order (if exists)
    yield put( loadOrder() );
  }
  catch( err ) {

    console.log( err );
    yield put( menuLoaded( id, null, err.message ) );
  }
}

export default function* menuSaga() {

  //yield takeEvery( LOCATION_SELECT_IDENTITY, handleLocationState );
  yield takeLatest( MENU_LOAD, handleMenuLoad );
}
