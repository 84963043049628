// search config

export const config = {

  // Configure redux-search by telling it which resources to index for searching
  resourceIndexes: {

    items: ({ resources, indexDocument, state }) => {

      for( let item of resources.values() ) {

        const { id, name, desc } = item;

        name && indexDocument( id, name );
        desc && indexDocument( id, desc );
      }
    },
  },

  // This selector is responsible for returning each collection of searchable resources
  resourceSelector: (resourceName, state) => {

    if( resourceName === 'items' ) {

      return state.menu.items;
    }
  },
};
