export const ORDER_ADD_ITEM = 'ORDER_ADD_ITEM';

export const ORDER_ADD_MULTIPLE_ITEMS = 'ORDER_ADD_MULTIPLE_ITEMS';

export const ORDER_UPDATE_ITEM = 'ORDER_UPDATE_ITEM';

export const ORDER_DELETE_ITEM = 'ORDER_DELETE_ITEM';

export const ORDER_LOAD = 'ORDER_LOAD';

export const addItem = (item, optionValues, quantity) => ({type: ORDER_ADD_ITEM, payload: { item, optionValues, quantity }});

export const addMultipleItems = (orderItems) => ({type:ORDER_ADD_MULTIPLE_ITEMS, payload: { orderItems } } );

export const updateItem = (id, optionValues, quantity) => ({type: ORDER_UPDATE_ITEM, payload: { id, optionValues, quantity}} );

export const deleteItem = (id) => ({type: ORDER_DELETE_ITEM, payload: { id }} );

export const loadOrder = () => ({type: ORDER_LOAD, payload: {}} );
