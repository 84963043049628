import React, { useState } from 'react';

import { Accordion, AccordionPanel, Box, Button, Main, Text, ThemeContext } from 'grommet';
import { FormClose, Search } from 'grommet-icons';

import { useDispatch, useSelector } from 'react-redux';

import { selectMenuItem, setExpandedSections, searchItems, filterItems } from 'actions/menu';
import { selectMenu, selectExpandedSections } from 'selectors/menu';
import { selectOrderItemCount } from 'selectors/order';

import { MenuContext, MenuHeader, MenuItem, Section } from '@candr-com/menu-common-ui';

import SearchLayer from './SearchLayer';

import Footer from 'components/Footer';

const MenuSection = ( { section, isCentered, onItemClick } ) => {

  return (
    <AccordionPanel label={
      <Box flex pad={{left: isCentered ? '36px' : 'small', top: 'small'}}>
        <Section section={section}/>
      </Box>
      }>
      <Box pad={{bottom: 'medium'}}>
      {
        section.items.map( (item) => (
          <Box key={item.id} pad={{left: 'small', right: 'small'}} onClick={() => onItemClick( item )}>
            <MenuItem item={item} key={item.id}/>
          </Box>
        ))
      }
      </Box>
    </AccordionPanel>
  )
};

const grommetTheme = {
  accordion: {

    border: {

      color: 'transparent',
    },
    hover: {

      // color: {
      //
      //   dark: 'trasparent',
      //   light: 'transparent',
      // }
    },
    icons: {

      color: '#AAA',
    },

    panel: {

      border: {

        color: {
          //
          // dark: 'transparent',
          // light: 'transparent',
        },
        style: 'hidden',
      },
    },
  },

  global: { focus: { border: { color: 'transparent' } } },
};

const MenuBar = ({name, orderItemCount, onOrder, onSearch}) => {

  return (
    <div style={{position: 'fixed', left: 0, top: 0, right: 0, zIndex: 10, height: 50, backgroundColor:"rgba( 230,230,230,0.95)"}}>
      <Box pad={{left: 'small', right: 'small'}} direction="row" fill align="center" justify="between">
        <Text size="medium" weight="bold">{name}</Text>
        <Box direction="row" gap="xsmall">
          <Button icon={<Search/>} onClick={onSearch}/>
          {
            orderItemCount > 0 &&
            <Box onClick={onOrder} align="center" justify="center" pad="small">
              <Box round="medium" background="tomato" pad={{top: "xsmall", bottom: 'xsmall', left: 'medium', right: 'medium'}}>
                <Text size="xsmall" color="white" weight="bold">{orderItemCount}</Text>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </div>
  );
}

const MenuPage = ({history}) => {

  const dispatch = useDispatch();

  const [showSearch, setShowSearch] = useState( false );

  const activeIndex = useSelector( selectExpandedSections );

  const { menu, filterTags, searchText } = useSelector( selectMenu );

  const orderItemCount = useSelector( selectOrderItemCount );

  const handleMenuItemClick = (item) => {

    dispatch( selectMenuItem( item ) );

    // move to saga?
    history.push( '/item' );
  }

  const showAccordian = ((!searchText || searchText.trim().length === 0) && filterTags.length === 0);

  const sectionCentered = (menu?.theme?.section?.align === 'center');

  const renderMenu = () => {

    if( showAccordian ) {

      return (
        <Accordion multiple={true} activeIndex={activeIndex} onActive={(values) => dispatch( setExpandedSections( values ) )}>
         {
           menu.sections.map( (section) => (<MenuSection isCentered={sectionCentered} section={section} key={section.id} onItemClick={handleMenuItemClick}/>) )
         }
        </Accordion>
      );
    }

    return (
      <>
        {
          menu.sections.map( (section) => (

            <Box key={section.id} margin={{top: 'small', bottom: 'medium'}}>
              <Section section={section}/>
              {
                section.items.map( (item) => (

                  <Box key={item.id} pad={{left: 'small', right: 'small'}} onClick={() => handleMenuItemClick( item )}>
                    <MenuItem item={item} key={item.id}/>
                  </Box>
                ))
              }
            </Box>
          ))
        }
      </>
    );
  };

  const clearSearchAndFilters = () => {

    dispatch( searchItems( '' ) );
    dispatch( filterItems( [] ) );
  };

  return (
    <Main justify="between" gap="xlarge">
        <ThemeContext.Extend value={grommetTheme}>
          <MenuContext.Provider value={{theme: menu.theme}}>
            <Box pad={{top: '50px'}} flex={false}>
            {
              !showAccordian &&
              <Box align="center" direction="row" background="neutral-2" pad="small">
                <Box flex gap="small">
                {
                  searchText && searchText.length > 0 &&
                  <Text><strong>{'Search: '}</strong>{searchText}</Text>
                }
                {
                  filterTags.length > 0 &&
                  <Text><strong>{'Filter: '}</strong>{ filterTags.join( ', ')}</Text>
                }
                </Box>
                <Button icon={<FormClose/>} onClick={clearSearchAndFilters}/>
              </Box>
            }
            <MenuHeader header={{logo: menu.logo}} onSelect={() => {}}/>
            { renderMenu() }
            { showSearch && <SearchLayer search={searchText} filterTags={filterTags} onClose={() => setShowSearch( false )}/>}
            <MenuBar
              name={menu.name}
              orderItemCount={orderItemCount}
              onSearch={() => setShowSearch(true)}
              onOrder={() => history.push( '/order' )}
            />
            </Box>
          </MenuContext.Provider>
        </ThemeContext.Extend>
        <Footer/>
    </Main>
  )
};


export default MenuPage;
