import { createSelector } from 'reselect';

import { getSearchSelectors } from 'redux-search'

import { renderSection, renderItem } from './helper';

import { makeMenuUrl } from 'lib/api';

const getSections = state => state.menu.sections;

const getItems = state => state.menu.items;

const getOptions = state => state.menu.options;

const getMenuLoading = state => state.menu.loading;

const getExpandedSections = state => state.menu.expandedSections;

const getMenuId = state => state.menu.id;

const getMenuName = state => state.menu.name;

const getMenuLogo = state => state.menu.logo;

const getTheme = state => state.menu.theme;

const getFilterTags = state => state.menu.filterTags;

export const selectMenuItemsMap = createSelector(

  getItems,
  getOptions,
  (itemsMap, optionsMap) => (

    new Map( [ ...itemsMap.values() ]
      .map( (item) => renderItem( item, optionsMap ) )
      .map( (item) => [item.id, item ] ) )
  )
);

export const selectSectionsMap = createSelector(

  getSections,
  selectMenuItemsMap,
  (sectionsMap, itemsMap) => (

    new Map( [...sectionsMap.values()]
      .map( (section) => renderSection( section, itemsMap ) )
      .map( (section) => [section.id, section] ) )
  )
);

export const selectMenuAll = createSelector(

  getMenuId,
  getMenuName,
  getMenuLogo,
  selectSectionsMap,
  getTheme,
  (id, name, logo, sections, theme) => ({

    id,
    name,
    logo: logo ? makeMenuUrl( id, logo ) : null,
    sections: [ ...sections.values() ],
    theme,
  })
);

const { text: getSearchText, result: getSearchIds } = getSearchSelectors({

    resourceName: 'items',
    resourceSelector: (resourceName, state) => state.menu.items
  });

const filterItem = (item, visibleItemIds, filterTags ) => {

  if( !visibleItemIds.has( item.id ) ) {

    return false;
  }

  if( filterTags.size === 0 ) {

    return true;
  }

  const tags = item.tags || [];

  for( const tag of tags ) {

    if( filterTags.has( tag ) ) {

      return true;
    }
  }

  return false;
};

export const selectMenu = createSelector(
  getSearchText,
  getSearchIds,
  getFilterTags,
  selectMenuAll,
  (searchText, visibleIds, filterTags, menu ) => {

    const visibleItemIds = new Set( visibleIds );

    const tags = new Set( filterTags );

    const { id: menuId, sections, ...menuOther } = menu;

    const filteredSections = sections.map( (section) => ({

        ...section,
        items: section.items.filter( (item) => filterItem( item, visibleItemIds, tags ) )
      }))
      .filter( (section ) => section.items.length > 0 );

    return {

      searchText,
      filterTags,
      menu: {
        id: menuId,
        sections: filteredSections,
        ...menuOther,
      },
    };
  }
);

export const selectMenuLoading = createSelector(

  getMenuLoading,
  (loading) => loading === true
);

export const selectExpandedSections = createSelector(

  getExpandedSections,
  (expandedSections) => expandedSections
);

export const selectMenuItems = createSelector(

  getItems,
  (items) => items
);

export const selectMenuId = createSelector(

  getMenuId,
  (id) => id
);

export const selectTags = createSelector(

  getItems,
  (itemsMap) => {

    const tags = new Set();

    [ ...itemsMap.values() ].forEach( (item) => (

      item.tags && item.tags.forEach( (tag) => tags.add( tag ))
    ));

    return [ ...tags.values() ].sort();
  }
);
