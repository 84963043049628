import React from 'react';

import { RadioButton, Table, TableBody, TableRow, TableCell } from 'grommet';

    //
    //   options: [
    //
    //     {
    //       name: <sring>
    //       price: number
    //       value: <string>
    //     }
    //     ...
    //   ],
    // },

const ItemOptionRadioTable = ({options, value, onChange}) => {

  return (
    <Table>
      <TableBody>
        {
          options.map( (option, index) => (
            <TableRow key={`row_${index}`} pad="large">
              <TableCell scope="row" size="medium" pad="small">
                <RadioButton
                  name={option.value}
                  label={option.name}
                  checked={value === option.value}
                  onChange={({ target: { checked } }) => checked && onChange( option.value )}
                />
              </TableCell>
              {
                option.price &&
                <TableCell>${option.price}
                </TableCell>
              }
            </TableRow>
          ) )
        }
      </TableBody>
    </Table>
  );
};

export default ItemOptionRadioTable;
