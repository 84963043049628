import { createSelector } from 'reselect';

import { selectMenuItemsMap } from './menu';

const getItems = state => state.order.items;

const renderOrderItem = ( orderItem, item ) => {

  const renderedOptions = [];

  const { optionValues } = orderItem;

  if( item.options && optionValues && item.options.length > 0 ) {

    for( const option of item.options ) {

      const value = optionValues.get( option.id );

      if( Array.isArray( value ) ) {

        renderedOptions.push( { name: option.name, value: value.map( (v) => option.options.get( v ).name ).join( ', ' ) } );
      }
      else if( value ) {

        renderedOptions.push( { name: option.name, value: option.options.get( value ).name } );
      }
    }
  }

  return { ...orderItem, item, renderedOptions };
}

export const selectOrderItems = createSelector(

  getItems,
  selectMenuItemsMap,
  (orders, itemsMap) => (

    [...orders.values() ].map( ({itemId, ...order}) => renderOrderItem( order, itemsMap.get( itemId )))
  ),
);

export const selectOrderItemCount = createSelector(

  selectOrderItems,
  (orders) => orders.reduce( (count, { quantity }) => count + quantity, 0 )
);
