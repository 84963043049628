import React from 'react';

import { Box, Button, Heading, List, Main, Text } from 'grommet';
import { Close, FormTrash} from 'grommet-icons';

import { useDispatch, useSelector } from 'react-redux';

import { selectMenuItem } from 'actions/menu';
import { deleteItem } from 'actions/order';

import { selectOrderItems } from 'selectors/order';


const OrderItem = ({orderItem, onChange, onDelete}) => {

  const { item, quantity, renderedOptions } = orderItem;

  const { name } = item;

  return (
    <Box direction="row" align="center" gap="xsmall">
      <Box pad="xsmall">
        <Text color="dark-3">{`${quantity}x `}</Text>
      </Box>
      <Box flex onClick={onChange}>
        <Heading level={3} margin="none" size="small">{name}</Heading>
        {
          renderedOptions &&
          renderedOptions.map( (option) => <Text key={option.value}>{`${option.name}: ${option.value}`}</Text> )
        }
      </Box>
      <Box>
        <Button icon={<FormTrash color="dark-3"/>} onClick={onDelete}/>
      </Box>
    </Box>
  );
}

const OrderPage = ({history}) => {

  const dispatch = useDispatch();

  const orderItems = useSelector( selectOrderItems );

  const handleChange = (orderItem) => {

    const { id: orderItemId, quantity, item, optionValues } = orderItem;

    dispatch( selectMenuItem( item, { optionValues, orderItemId, quantity } ) );

    history.push( '/item' );
  };

  const handleDelete = (orderItem) => {

    dispatch( deleteItem( orderItem.id ) );
  };

  return (
    <Box>
      <Box pad={{left: 'medium'}} background="accent-2" align="center" justify="between" direction="row" animation="fadeIn">
        <Heading color="white" pad="medium" level={2}>View Order</Heading>
        <Button pad="xsmall" icon={<Close color="white"/>} onClick={() => history.goBack()}/>
      </Box>
      <List
        data={orderItems}
        pad={{left: 'small', top: 'small', bottom: 'small'}}
        primaryKey={(orderItem, index) => (
          <OrderItem
            key={orderItem.id}
            orderItem={orderItem}
            onChange={() => handleChange( orderItem )}
            onDelete={() => handleDelete( orderItem )}
          />
        )}
      />
    </Box>
  );
};

export default OrderPage;
