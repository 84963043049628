import { combineReducers } from 'redux';

import menu from './menu';
import menu_item from './menu_item';
import order from './order';

import { reducer as search } from 'redux-search'

const rootReducer = () => combineReducers( {

  menu,
  menu_item,
  order,

  search,
});


export default rootReducer;
