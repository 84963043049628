//button.border.radius

const theme = {
  global: {
    colors: {

      brand: '#1280F8',
      'neutral-1': '#177e89',
      'neutral-2': '#33658a',
      'accent-1': '#caf0f8',
      'accent-2': '#f86624',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
    focus: {
      border: {
        color: 'transparent'
      },
    },
  },
  button: {

  },
};

export default theme;
