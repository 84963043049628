import { createSearchAction } from 'redux-search'

export const MENU_LOAD = 'MENU_LOAD';

export const MENU_LOADED = 'MENU_LOADED';

export const MENU_SET_EXPANDED_SECTIONS = 'MENU_SET_EXPANDED_SECTIONS';

export const MENU_SELECT_ITEM = 'MENU_SELECT_ITEM';

export const loadMenu = (id) => ({type: MENU_LOAD, payload: { id }});

export const menuLoaded = (id, data, error ) => ({type: MENU_LOADED, payload: { id, data, error }});

export const setExpandedSections = (expandedSections) => ({type: MENU_SET_EXPANDED_SECTIONS, payload: { expandedSections }} );

export const selectMenuItem = (item, options ) => ({type: MENU_SELECT_ITEM, payload: {item, options}} );

export const searchItems = createSearchAction( 'items' );

export const MENU_FILTER_ITEMS = 'MENU_FILTER_ITEMS';

export const filterItems = (tags) => ({type: MENU_FILTER_ITEMS, payload: { tags }} );
