import React from 'react';

import { Anchor, Box, Footer, Text } from 'grommet';

const PageFooter = () => {

  return (
    <Footer justify="center" pad="medium" background="dark-2">
        <Box direction="row" gap="xsmall" align="center">
          <Text size="small">Powered by</Text>
          <Anchor size="small" href="https://www.candr.com" target="_blank" rel="noopener noreferrer">candr.com</Anchor>
        </Box>
        <Box>
          <Text size="small">&copy; 2020 candr.com</Text>
        </Box>
    </Footer>
  );
};

export default PageFooter;
