import { handleActions } from './handle-actions';

import cuid from 'cuid';

import { MENU_LOADED } from 'actions/menu';

import { ORDER_ADD_ITEM, ORDER_ADD_MULTIPLE_ITEMS, ORDER_UPDATE_ITEM,
         ORDER_DELETE_ITEM
       } from 'actions/order';

const addItem = (draft, { item, optionValues, quantity } ) => {

  const id = cuid();

  draft.items.set( id, { id, itemId: item.id, optionValues, quantity } );
}

const DEFAULT_STATE = {

  items: new Map(),
};

const reducer = handleActions( {

    [ MENU_LOADED ]: ( draft, { data } ) => {

      draft.items = new Map();
    },

    [ ORDER_ADD_ITEM ]: ( draft, { item, optionValues, quantity } ) => {

      addItem( draft, { item, optionValues, quantity } );
    },

    [ ORDER_ADD_MULTIPLE_ITEMS ]: ( draft, { orderItems } ) => {

      orderItems.forEach( (orderItem) => addItem( draft, orderItem ) );
    },

    [ ORDER_UPDATE_ITEM ]: ( draft, { id, optionValues, quantity } ) => {

      const orderItem = draft.items.get( id );

      if( orderItem ) {

        orderItem.optionValues = optionValues;
        orderItem.quantity = quantity;
      }
    },

    [ ORDER_DELETE_ITEM ]: ( draft, { id } ) => {

      draft.items.delete( id );
    },
  },
  DEFAULT_STATE,
);

export default reducer;
