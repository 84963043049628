import { all } from 'redux-saga/effects';

import menuSaga from './menu';
import orderSaga from './order';

export default function* rootSaga() {

  yield all([
    menuSaga(),
    orderSaga(),
  ]);
}
