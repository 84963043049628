import { handleActions } from './handle-actions';

import { MENU_LOADED, MENU_SELECT_ITEM } from 'actions/menu';

import { MENU_ITEM_SET_OPTION_VALUE, MENU_ITEM_UPDATE_QUANTITY } from 'actions/menu_item';

const DEFAULT_STATE = {

  item: null,
  optionValues: new Map(),
  quantity: 1,
  orderItemId: null,
};

const reducer = handleActions( {

    [ MENU_LOADED ]: ( draft, { data } ) => {

      draft.item = null;
      draft.optionValues = new Map();
      draft.quantity = 1;
      draft.orderItemId = null;
    },

    [ MENU_SELECT_ITEM ]: ( draft, { item, options = {} } ) => {

      const { optionValues = new Map(), quantity = 1, orderItemId } = options;

      draft.item = item;
      draft.optionValues = optionValues;
      draft.quantity = quantity;
      draft.orderItemId = orderItemId;
    },

    [MENU_ITEM_SET_OPTION_VALUE]: ( draft, { id, value, op } ) => {

      const existing = draft.optionValues.get( id );

      if( op === 'add' ) {

        draft.optionValues.set( id, [ ...(existing || []), value ] );
      }
      else if( op === 'remove' ) {

        draft.optionValues.set( id, (existing || []).filter( (v) => v !== value ) );
      }
      else {

        draft.optionValues.set( id, value );
      }
    },

    [MENU_ITEM_UPDATE_QUANTITY]: (draft, { quantity } ) => {

      draft.quantity = quantity;
    }
  },
  DEFAULT_STATE,
);

export default reducer;
