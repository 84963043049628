import { createSelector } from 'reselect';

const getItem = state => state.menu_item.item;

const getOptionValues = state => state.menu_item.optionValues;

const getQuantity = state => state.menu_item.quantity;

const getOrderItemId = state => state.menu_item.orderItemId;

export const selectMenuItem = createSelector(

  getItem,
  getOptionValues,
  getQuantity,
  getOrderItemId,
  (item, optionValues, quantity, orderItemId) => ({

    item,
    optionValues,
    quantity,
    orderItemId,
  })
);
