import axios from 'axios';

export const makeMenuUrl = ( id, assetName ) => `https://menu.assets.candr.com/${id}/${assetName}`;

const GET = async ( url, config ) => {

  return await axios.get( url, config );
};

export const getMenu = async ( id ) => {

  const { data: menu } = await GET( makeMenuUrl( id, 'menu.json' ) );

  return menu;
};
