import { debounce, put, select, takeEvery } from 'redux-saga/effects';

import ls from 'local-storage';

import { ORDER_ADD_ITEM, ORDER_DELETE_ITEM, ORDER_UPDATE_ITEM,
         ORDER_ADD_MULTIPLE_ITEMS, ORDER_LOAD,
         addMultipleItems,
       } from 'actions/order';

import { selectMenuId, selectMenuItemsMap } from 'selectors/menu';
import { selectOrderItems } from 'selectors/order';

const orderActionTypes = new Set( [ ORDER_ADD_ITEM, ORDER_UPDATE_ITEM, ORDER_DELETE_ITEM, ORDER_ADD_MULTIPLE_ITEMS ] );

const ORDER_EXPIRE_TIMEOUT = 1000 * 60 * 60 * 3;  // 3 hours

const retoreOptionValues = (item, orderItem) => {

  const optionValues = new Map();

  const incomingOptionValues = new Map( orderItem.options );

  for( const option of item.options.values() ) {

    let value = incomingOptionValues.get( option.id );

    if( option.type === 'radio' || option.type === 'size' ) {

      if( !option.options.has( value ) ) {

        // select first value
        value = option.options.values().next().value;
      }
    }
    else if( option.type === 'checkbox' ) {

      value = value.filter( (val) => option.options.has( val ) );
    }

    optionValues.set( option.id, value );
  }

  return optionValues;
}

const processOrder = ( orderData, menuItemsMap ) => {

  try {

    const { orderItems = [] } = orderData;

    const itemsToAdd = [];

    for( const orderItem of orderItems ) {

      const item = menuItemsMap.get( orderItem.id );

      if( !item ) {

        continue;
      }

      try {

        const optionValues = retoreOptionValues( item, orderItem );

        const quantity = Math.max( 1, Math.min( orderItem.quantity, 30 ) );

        itemsToAdd.push( { item, optionValues, quantity } );
      }
      catch( err ) {

        console.log( err );
        // ignore this item
        continue;
      }
    }

    return itemsToAdd;
  }
  catch( err ) {

    console.log( err.message );
  }
}

const parsePersisted = (persisted) => {

  try {

    const orderData = JSON.parse( persisted );

    const { version = 0, orderItems = [], time = 0 } = orderData;

    if( version !== 1 ) {

      throw new Error( 'version not supported' );
    }

    const now = Date.now();

    if( (time + ORDER_EXPIRE_TIMEOUT) < now || time > now ) {

      throw new Error( 'expired' );
    }

    if( !Array.isArray( orderItems ) ) {

      throw new Error( 'missing order items' );
    }

    return orderData;
  }
  catch( err ) {

    console.log( 'cannot restore order: ', err.message );
  }
}

function* handleLoadOrder() {

  const menuId = yield select( selectMenuId );

  try {

    const persisted = ls.get( `candr.menu.order.${menuId}` );

    if( persisted ) {

      const orderData = parsePersisted( persisted );

      if( !orderData ) {

        // no good - kill entry
        ls.remove( `candr.menu.order.${menuId}` );

        return;
      }

      const menuItemsMap = yield select( selectMenuItemsMap );

      const orderItems = processOrder( orderData, menuItemsMap );

      yield put( addMultipleItems( orderItems ) );
    }
  }
  catch( err ) {

    console.log( err.message );
  }
}

function* handlePersistOrder() {

  const menuId = yield select( selectMenuId );
  const orderItems = yield select( selectOrderItems );

  const persistObject = {

    version: 1,
    menuId,
    time: Date.now(),
    orderItems: orderItems.map( ({item, optionValues, quantity}) => ({

        id: item.id,
        options: [ ...optionValues.entries() ],
        quantity: quantity,
      }) ),
  };

  ls.set( `candr.menu.order.${menuId}`, JSON.stringify( persistObject ) );
}


const isOrderAction = ( action ) => orderActionTypes.has( action.type );

export default function* orderSaga() {

  yield takeEvery( ORDER_LOAD, handleLoadOrder );
  yield debounce( 1000, isOrderAction, handlePersistOrder );
}
