export const renderItem = (item, optionsMap) => {

  const renderedItem = { ...item };

  if( item.options && item.options.length > 0 ) {

    renderedItem.options = item.options.map( (optId) => optionsMap.get(optId) );
  }

  return renderedItem;
}

export const renderSection = (section, itemsMap ) => {

  const renderedSection = {

    ...section,
    items: section.items.filter( (id) => itemsMap.has( id )).map( (id) => itemsMap.get( id )  )
  };

  return renderedSection;
};
