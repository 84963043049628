import { handleActions } from './handle-actions';

import { MENU_LOAD, MENU_LOADED, MENU_SET_EXPANDED_SECTIONS, MENU_FILTER_ITEMS } from 'actions/menu';

const processItem = ({options, ...item}) => ({

  ...item,
  options: options || [],
});

const processOption = ({options,...option}) => ({

  ...option,
  options: new Map( options.map( (opt) => [opt.value, opt]))
});

const DEFAULT_STATE = {

  loading: false,
  id: null,
  sections: new Map(),
  items: new Map(),
  options: new Map(),
  expandedSections: [],
  theme: {},
  filterTags: [],
};

const reducer = handleActions( {

    [ MENU_LOAD ]: ( draft, { id } ) => {

      draft.loading = true;
    },

    [ MENU_LOADED ]: ( draft, { id, data, error } ) => {

      draft.loading = false;
      draft.id = data.id;
      draft.name = data.name;
      draft.logo = data.logo;
      draft.sections = new Map( (data.sections || []).map( (section) => [section.id, section ] ) );
      draft.items = new Map( (data.items || []).map( (item) => [item.id, processItem( item ) ] ) );
      draft.options = new Map( (data.options || []).map( (option) => [option.id, processOption( option )] ) );
      draft.theme = data.theme || {};
      draft.filterTags = [];
    },

    [ MENU_SET_EXPANDED_SECTIONS ]: ( draft, { expandedSections } ) => {

      draft.expandedSections = expandedSections || [];
    },

    [ MENU_FILTER_ITEMS ]: ( draft, { tags } ) => {

      draft.filterTags = tags;
    },
  },
  DEFAULT_STATE,
);

export default reducer;
