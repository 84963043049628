import React, { useEffect } from 'react';

import MenuPage from './pages/MenuPage';
import MenuItemPage from './pages/MenuItemPage';
import OrderPage from './pages/OrderPage';

import { Switch, Redirect, Route } from "react-router-dom";

import { Grommet } from 'grommet'

import { useDispatch } from 'react-redux';

import { loadMenu } from 'actions/menu';

import theme from './theme';

function App() {

  const dispatch = useDispatch();

  useEffect( () => {

    dispatch( loadMenu( 'qUhr40yun7aiTx96a5PK_' ) );
  }, [dispatch] );

  return (
    <Grommet theme={theme} full>
      <Switch>
        <Route path="/item" component={MenuItemPage}/>
        <Route path="/order" component={OrderPage}/>
        <Route path="/" exact component={MenuPage}/>
        <Route path="*">
          <Redirect to="/"/>
        </Route>
      </Switch>
    </Grommet>
  );
}

export default App;
